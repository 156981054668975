import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronsRight, Close } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import { Link } from 'gatsby'
import React, { memo, useState } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  file?: string
  label?: string
  languageCode: string
  image?: ImageProps
  showModal?: boolean
  title?: string
  URL?: string
}

export const FlashNews = memo(function FlashNews({
  cta,
  description,
  file,
  label,
  languageCode,
  image,
  showModal,
  title,
  URL,
}: Props) {
  if (!label) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [stickerStatus, setStickerStatus] = useState(true)
  const [modalStickerStatus, setModalStickerStatus] = useState(false)
  const [modalForceShow, setmodalForceShow] = useState(showModal)
  const buttonDOM = (
    <LabelContent>
      {label}
      <ChevronsRight />
    </LabelContent>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <Container className={!stickerStatus ? 'closed' : undefined}>
      <CloseButton onClick={() => setStickerStatus(false)}>
        <Close />
      </CloseButton>

      {label ? (
        URL ? (
          externalURL ? (
            <ExtLink href={URL} rel="noopener" target="_blank">
              {buttonDOM}
            </ExtLink>
          ) : (
            <Anchor to={URL}>{buttonDOM}</Anchor>
          )
        ) : file ? (
          <ExtLink href={file} rel="noopener" target="_blank">
            {buttonDOM}
          </ExtLink>
        ) : (
          <Label
            onClick={() => {
              if (!modalStickerStatus) {
                setLocked(true)
                setModalStickerStatus(true)
              } else {
                setLocked(false)
                setModalStickerStatus(false)
              }
            }}
          >
            {buttonDOM}
          </Label>
        )
      ) : null}

      <Modal
        className={`${modalStickerStatus || modalForceShow ? 'open' : ''}`}
        dial={5}
        onClick={() => {
          setLocked(false)
          setModalStickerStatus(false)
          setmodalForceShow(false)
        }}
      >
        <Wrapper row stretch onClick={(e) => e.stopPropagation()}>
          {image ? (
            <ImageWrap>
              <Image media="(min-width: 1920px)" {...image} />
            </ImageWrap>
          ) : null}
          <TextWrap className={!image ? 'full-width' : undefined}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                setLocked(false)
                setModalStickerStatus(false)
                setmodalForceShow(false)
              }}
              variant="compact"
            />
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta ? <CTA variant="simple" {...cta} /> : null}
          </TextWrap>
        </Wrapper>
      </Modal>
    </Container>
  )
})

const Container = styled.aside`
  position: relative;
  &.closed {
    display: none;
  }

  @media (max-width: 1199px) {
    width: 100%;
    position: absolute;
    top: 3.75rem;
    left: 0;
  }
`

const CloseButton = styled.span`
  width: 0.875rem;
  height: 0.875rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 3.75rem;
  z-index: 2;
  transform: translateY(-50%);

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transform: translate(-0.0625rem, -0.0625rem);
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.variants.primaryDark};
  color: ${theme.colors.variants.neutralLight4};
  cursor: default;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 1.3125rem;
  padding: 0.875rem 1.875rem 0.8125rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-out;

  svg {
    width: auto;
    height: 0.75rem;
    fill: none;
    margin-left: 0.75rem;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transform: translateY(0.0625rem);
  }
`

const Label = styled.div`
  ${Style}
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const LabelContent = styled.span`
  cursor: pointer;
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;

    > div {
      transform: translateY(0);
    }
  }
  &.first {
    animation: opacity 0.15s;
    > div {
      animation: fadeWrapper 0.6s;
    }
    img {
      animation: opacity 1s;
    }
  }

  @keyframes fadeWrapper {
    0% {
      transform: translateY(5.625rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: default;
  margin: auto;
  transform: translateY(5.625rem);
  transition: 0.6s;

  @media (max-width: 767px) {
    display: block;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    transform: none;
    -webkit-overflow-scrolling: auto;
  }
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 60%;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 5.625rem 3.33vw 3.75rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5rem 2rem 3rem;
  }

  @media (max-width: 767px) {
    padding-top: 3rem;

    > div {
      &:first-of-type {
        top: 1.25rem;
        right: 1.25rem;
        div {
          &::before,
          &:after {
            background: ${({ theme }) => theme.colors.variants.neutralLight4};
          }
        }
      }
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  letter-spacing: 0.1875rem;
  line-height: 2.25rem;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  display: -webkit-box;
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
